import { helpers } from 'vuelidate/lib/validators';

export const validateVvId = helpers.regex(
	'validateVvid',
	/(^\d{8}-\d{6}-\d{2}$|^$)/
);
export const contains = (param: any) => (value: any) => {
	if (!helpers.req(value)) return true;
	return value.indexOf(param) >= 0;
};
export const validateEmail = helpers.regex(
	'validateEmail',
	// eslint-disable-next-line no-control-regex
	/^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9äöüß](?:[a-z0-9äöüß-]*[a-z0-9äöüß])?\.)+[a-z0-9äöüß]{2,}(?:[a-z0-9äöüß-]*[a-z0-9äöüß])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9äöüß-]*[a-z0-9äöüß]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i
);
export const validateColor = helpers.regex(
	'validateColor',
	// eslint-disable-next-line no-control-regex
	/^#[A-Z]{6}$/
);
export const validateNoSpecialChars = helpers.regex(
	'validateNoSpecialChars',
	// eslint-disable-next-line no-control-regex
	/^[a-zA-Z0-9_.-]*$/
);
