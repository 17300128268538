



















































import { Component, Watch } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { LiveVideoBackground } from '@/models/liveVideoBackground';
import LiveVideoBackgroundService from '@/service/LiveVideoBackgroundService';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import Base from '@/views/Base';
import CreateBackgroundModal from '@/views/presentation/liveVideoBackgrounds/CreateBackgroundModal.vue';
import EditBackgroundModal from '@/views/presentation/liveVideoBackgrounds/EditBackgroundModal.vue';
import PreviewModal from '@/views/presentation/liveVideoBackgrounds/PreviewModal.vue';

@Component({
  components: { PreviewModal, EditBackgroundModal, CreateBackgroundModal, WListOptions, HeaderLayout }
})
export default class Index extends Base {
  backgrounds: LiveVideoBackground[] = [];
  editingBackground: LiveVideoBackground | null = null;

  currentBackground: LiveVideoBackground | null = null;

  options = {
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC'
  };

  @Watch('options.searchTerm')
  searchUpdated() {
    this.loadBackgrounds();
  }

  sort() {
    this.loadBackgrounds();
  }

  showNewModal() {
    this.$bvModal.show('createBackgroundModal');
  }

  loadBackgrounds() {
    LiveVideoBackgroundService.getVideobackgrounds(this.options.searchTerm, this.options.sortBy, this.options.sortDirection)
      .then(bg=>{
        this.backgrounds = bg;
        if(this.backgrounds.length > 0){
          this.currentBackground = this.backgrounds[0];
        }
      })
      .catch(this.showNetworkError);
  }

  async mounted() {
    this.loadBackgrounds();
  }

  photoUrl(bg: LiveVideoBackground): string {
    return LiveVideoBackgroundService.getPhotoUrl(bg.id, bg.filename);
  }

  editBackground(bg: LiveVideoBackground) {
    this.editingBackground = bg;
    this.$bvModal.show('editBackgroundModal');
  }

  previewBackground(bg: LiveVideoBackground){
    this.currentBackground = bg;
    this.$bvModal.show("backgroundPreviewModal")
  }

  async deleteBackground(bg: LiveVideoBackground) {
    if (await this.$bvModal.msgBoxConfirm(this.t('presentation.liveVideoBackgrounds.deleteConfirmModalText'), {
      okVariant: 'danger',
      okTitle: this.t('presentation.liveVideoBackgrounds.deleteConfirmModalOk'),
      cancelTitle: this.t('common.cancel'),
      centered: true,
      title: this.t('presentation.liveVideoBackgrounds.deleteConfirmModalTitle')
    })) {
      await LiveVideoBackgroundService.deleteVideoBackground(bg.id);
      this.toast(this.t('presentation.liveVideoBackgrounds.deletedToast'), 'danger');
      await this.loadBackgrounds();
    }
  }

}
