import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/i18n/en';
import de from '@/i18n/de';

const messages = {
	en: { ...en },
	de: { ...de }
};

Vue.use(VueI18n);

function getDefaultLocale(): string{
	const urlLangParam = new URLSearchParams(window.location.search).get("lang");
	if(urlLangParam && Object.keys(messages).some(lang => lang.toLowerCase() === urlLangParam.toLowerCase())) {
		console.log("Using url language parameter");
		localStorage.setItem("language", urlLangParam);
		return urlLangParam;
	}
	return localStorage.getItem('language') || navigator.languages[0]
}

// Create VueI18n instance with options
export default new VueI18n({
	locale: getDefaultLocale(), // set locale
	fallbackLocale: 'de',
	messages // set locale messages
});
