





























import { Component, Prop } from 'vue-property-decorator';
import Base from '@/views/Base';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { BvModalEvent } from 'bootstrap-vue';
import LiveVideoBackgroundService from '@/service/LiveVideoBackgroundService';
import { LiveVideoBackground } from '@/models/liveVideoBackground';

@Component({})
export default class EditBackgroundModal extends Base {
  title = '';
  loading = false;

  @Prop() editingBackground!: LiveVideoBackground;

  @Validations()
  validations = {
    title: { required },
  };

  show(){
    this.$v.$reset();
    this.title = this.editingBackground.title;
  }

  reset() {
    this.$v.$reset();
    this.title = '';
  }

  async submit(event: BvModalEvent) {
    event.preventDefault();
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.loading = true;

    await LiveVideoBackgroundService.updateVideoBackground(this.editingBackground.id, {
      title: this.title
    });

    this.loading = false;

    this.$emit('edited')
    this.$bvModal.hide('editBackgroundModal');
  }
}
