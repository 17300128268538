import axios from 'axios';
import { CreateLiveVideoBackground, LiveVideoBackground } from '@/models/liveVideoBackground';

export default {
  getVideobackgrounds(search: string, order: string, direction: string): Promise<LiveVideoBackground[]> {
    const params = {
      search, order, direction
    }
    return axios.get('/live-video-background', { params }).then((response) => response.data);
  },
  getVideoBackground(id: number): Promise<LiveVideoBackground> {
    return axios.get(`/live-video-background/${id}`).then((response) => response.data);
  },
  createVideoBackground(bg: CreateLiveVideoBackground): Promise<LiveVideoBackground> {
    return axios.post('/live-video-background', bg).then((response) => response.data);
  },
  updateVideoBackground(id: number, bg: CreateLiveVideoBackground): Promise<LiveVideoBackground> {
    return axios.put('/live-video-background/' + id, bg).then((response) => response.data);
  },
  deleteVideoBackground(id: number): Promise<void> {
    return axios.delete(`/live-video-background/${id}`).then((response) => response.data);
  },
  uploadPhoto(id: number, file: File): Promise<void> {
    const form = new FormData();
    form.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios
      .post(`/live-video-background/${id}/photo`, form, { headers })
      .then((res) => res.data);

  },
  getPhotoUrl(id: number, filename: string): string {
    return axios.defaults.baseURL + '/live-video-background/' + id + '/' + filename;
  },
};