
























































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { throttle } from 'lodash';

@Component
export default class HeaderLayout extends Vue {
	@Prop() title!: string;
	@Prop() subtitle!: string;
	@Prop() secondSubtitle!: string;
	@Prop() bread!: any[];
	@Prop({ type: Boolean, default: false }) notFixed!: boolean;
	@Prop() cols!: number;
	@Prop() offset!: number;
	@Prop() md!: number;
	@Prop() offsetMd!: number;
	@Prop() lg!: number;
	@Prop() offsetLg!: number;
	@Prop() xl!: number;
	@Prop() offsetXl!: number;
	@Prop() sm!: number;
	@Prop() xs!: number;
	@Prop({ default: null }) newButton!: string | { name: string; query?: any };
  @Prop({default: false, type: Boolean}) showNewButton!: boolean;

	headerHeight = '0px';
	throttleFunction = throttle(this.shrinkHeader, 50);
	shrinkClass = false;

	mounted(): void {
		this.updateHeaderHeight();
    window.addEventListener('resize', this.updateHeaderHeight, false)
	}

  updateHeaderHeight(){
    const header = this.$refs['header'] as HTMLElement;
    this.headerHeight = '108px';
    this.shrinkHeader = this.shrinkHeader.bind(this);
    if (window.innerWidth > 768) {
      window.addEventListener('scroll', this.throttleFunction, false);
    } else {
      this.headerHeight = header.offsetHeight + 12 + 'px' || '0';
    }
  }

	beforeDestroy(): void {
		if (window.innerWidth > 768) {
			window.removeEventListener('scroll', this.throttleFunction, false);
		}
    window.removeEventListener('resize', this.updateHeaderHeight, false);
	}

	shrinkHeader(): void {
		this.shrinkClass = window.scrollY > 50;
	}
}
