









































































import { Component } from 'vue-property-decorator';
import Base from '@/views/Base';
import { ImageData } from '@/models/SpeakerCard';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { BvModalEvent } from 'bootstrap-vue';
import LiveVideoBackgroundService from '@/service/LiveVideoBackgroundService';
import { dataURLToBlob } from '@/utils/images';

@Component({})
export default class CreateBackgroundModal extends Base {
  photoData: ImageData = {
    file: null,
    url: null
  };
  title = '';
  loading = false;

  imageSize: {
    width: number,
    height: number,
    uploadedWidth: number,
    uploadedHeight: number,
  } | null = null;

  @Validations()
  validations = {
    title: { required },
    photoData: {
      file: { required },
      url: { required }
    }
  };

  get sizeWarning(): string {
    if (!this.imageSize) {
      return '';
    }
    if (this.imageSize.width < 1280 || this.imageSize.height < 720) {
      return this.t('presentation.liveVideoBackgrounds.sizeWarning');
    }
    return '';
  }

  get ratioWarning(): string {
    if (!this.imageSize) {
      return '';
    }
    // Not similar to 16:9
    if (Math.abs(this.imageSize.uploadedWidth / this.imageSize.uploadedHeight - 16 / 9) > 0.1) {
      return this.t('presentation.liveVideoBackgrounds.ratioWarning');
    }
    return '';
  }

  reset() {
    this.$v.$reset();
    this.title = '';
    this.photoData.url = null;
    this.photoData.file = null;
    this.imageSize = null;
  }

  async submit(event: BvModalEvent) {
    event.preventDefault();
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    if (this.photoData.file == null) {
      // unreachable
      return;
    }
    this.loading = true;
    try {
      const bg = await LiveVideoBackgroundService.createVideoBackground({
        title: this.title
      });
      await LiveVideoBackgroundService.uploadPhoto(bg.id, this.photoData.file);
    } catch (e) {
      this.showNetworkError(e);
      this.loading = false;
      return;
    }

    this.loading = false;
    this.$emit('created');
    this.$bvModal.hide('createBackgroundModal');
  }

  photoUploaded(files: File[]): void {
    const file: Blob = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          const targetAspectRatio = 16 / 9;

          let cropHeight = height;
          let cropWidth = width;

          if (width / height > targetAspectRatio) {
            // Width too large
            console.log("Width too large");
            cropWidth = height * targetAspectRatio;
          } else {
            // height too large
            cropHeight = Math.round(width / targetAspectRatio);
          }

          const canvas = document.createElement('canvas');
          canvas.width = cropWidth;
          canvas.height = cropHeight;
          const ctx = canvas.getContext('2d');

          if (ctx) {
            // Bild wird genau zentriert zugeschnitten
            ctx.drawImage(
              img,
              (width - cropWidth) / 2,
              (height - cropHeight) / 2,
              cropWidth,
              cropHeight,
              0,
              0,
              // Workaround for a black bar
              canvas.width + 1,
              canvas.height
            );

            this.imageSize = {
              width: cropWidth,
              height: cropHeight,
              uploadedWidth: width,
              uploadedHeight: height
            };

            this.photoData.url = canvas.toDataURL('image/jpeg');
            const blob = dataURLToBlob(this.photoData.url);
            this.photoData.file = new File([blob], 'img.jpg');

            console.log('Image cropped and uploaded');
          }
        };
        img.onerror = () => {
          this.toast(this.t('presentation.liveVideoBackgrounds.imageUploadFailed'), "danger")
        }
      }
    };
  }


  deletePhoto() {
    this.photoData.url = null;
    this.photoData.file = null;
    this.imageSize = null;
  }

}
