import Vue from 'vue';

export enum Events {
	Logout = 'logout',
	DeleteConference = 'deleteConference',
	EditConference = 'editConference',
	EditConferenceForm = 'editConferenceForm',
	EditConferenceSurvey = 'editConferenceSurvey',
	OpenCCP = 'openCCP',
	RepeatConference = 'repeatConference',
	ConferenceDetail = 'conferenceDetail',
	EndConference = 'endConference',
	StartConference = 'startConference',
	AuthenticationCleared = 'AuthenticationCleared',
	CollapseAll = 'CollapseAll',
	ExpandAll = 'ExpandAll',
}

export const EventBus = new Vue();
