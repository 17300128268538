
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { LiveVideoBackground } from '@/models/liveVideoBackground';
import LiveVideoBackgroundService from '@/service/LiveVideoBackgroundService';
import Base from '@/views/Base';

@Component({})
export default class PreviewModal extends Base {
  @Prop() currentBackground!: LiveVideoBackground;

  get imageUrl(){
    return LiveVideoBackgroundService.getPhotoUrl(this.currentBackground.id, this.currentBackground.filename);
  }

}
